#terms {
    background-color: white;
}

#terms h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-top: 34px;
    text-align: center;
}

#terms h2 {
    margin-top: 0;
    font-size: 18px;
}

#terms section {
    margin-bottom: 20px;
}

#terms p {
    margin: 10px 0;
}

#terms main {
    margin-bottom: 32px;
}

#terms main {
    margin-bottom: 32px;
    line-height: 1.6;
}

#terms p,
#terms li {
    margin-bottom: 1em;
    list-style-type: disc;
}

#terms ul {
    margin-bottom: 1em;
    padding-left: 1.5em;
}
