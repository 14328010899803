.calendly-inline-widget {
    animation: load-calendly;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}

@keyframes load-calendly {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.calendly {
    transition: 1s;
    animation: load-calendly;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}

@keyframes load-calendly {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.calendly a {
    color: rgba(255, 119, 0, 0.8);
    transition: 0.8s;
}

.calendly a:hover {
    color: rgba(255, 119, 0, 0.5);
}

.mobile .calendly {
    text-align: center;
}

.calendly {
    width: 100%;
    text-align: center;
}

.calendly h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-top: 34px;
}

.calendly .disclaimer,
.calendly .snippet {
    width: 100%;
    text-align: left;
    margin: auto;
    font-size: 16px;
    transition: 0s;
}

.calendly .placeholder {
    height: 400px;
    width: 70%;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 8px 0px;
    margin: 64px auto;
}

.calendly .placeholder {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    overflow: hidden;
}

.calendly .placeholder .icon svg {
    width: 64px;
    height: 64px;
    animation: calendly-loading 2s cubic-bezier(0.25, 0.1, 0.25, 1) infinite;
}

.calendly .waiver {
    font-size: 14px;
    opacity: 0.75;
}

.calendly .waiver a {
    opacity: 1;
    font-weight: 800;
}

@keyframes calendly-loading {
    0% {
        transform: scale(0.9);
        opacity: 0.8;
    }

    50% {
        transform: scale(1.1);
        opacity: 1;
    }

    100% {
        transform: scale(0.9);
        opacity: 0.8;
    }
}

@media (max-width: 650px) {
    .calendly .placeholder {
        width: 100%;
        margin: 48px auto;
        box-shadow: none;
    }
}

@media (max-width: 800px) {}

@media (max-width: 750px) {
    .calendly .snippet {
        margin-top: 32px;
        margin-bottom: 16px;
    }

    .calendly .disclaimer {
        margin-bottom: 32px;
    }
}

@media (min-width: 600px) {

    .calendly .disclaimer,
    .calendly .snippet {
        width: 100%;
        max-width: 800px;
        text-align: left;
    }

    .calendly .disclaimer {
        margin-top: 32px;
        margin-bottom: 16px;
    }

    .calendly .waiver {
        margin: auto;
        margin-bottom: 32px;
        width: 550px;
    }

}

@media (min-width: 1000px) {

    .calendly .disclaimer,
    .calendly .snippet {
        text-align: left;
    }

    .calendly .disclaimer,
    .calendly .snippet,
    .calendly .waiver {
        width: 800px;
    }

    .calendly .placeholder {
        width: 1058px;
    }

    .calendly .container {
        width: 85%;
    }
}

[data-container="booking-container"] {
    box-shadow: none !important;
}