/**
 *  landing
 */
 .landing {
    animation: load-landing;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;    
  }

  @keyframes load-landing {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  } 


/**
 *  desktop
 */
.landing.desktop {
    height: 100%;
}
.landing.desktop .flex-container {
    display: flex;
    flex-direction: row;
    font-size: 30px;
    text-align: center;    
    margin: 48px 16px 74px 48px;
}
.landing.desktop .flex-container .flex-item-left {
    background-color: #ffffff;
    padding: 0px;
    flex: 20%;
    line-height: 0;
    height: 675px;
}
.landing.desktop .flex-container .flex-item-left .title {
    height: 12px;
    width: 400px;
    position: relative;
    top: 8px;
    left: 64px;
    text-align: left;
}
.landing.desktop .flex-container .flex-item-left .title h4 {
    font-size: 36px;
}
.landing.desktop .flex-container .flex-item-left .brief {
    height: 12px;
    width: 480px;
    position: relative;
    top: 124px;
    left: 64px;
    text-align: left;
}
.landing.desktop .flex-container .flex-item-left .brief p {
    font-size: 24px;
    line-height: 24px;
}
.landing.desktop .flex-container .flex-item-left .brief .link {
    text-align: left;
    margin: 48px 0;
}
.landing.desktop .flex-container .flex-item-left .brief .link button {
    transition: 0.2s ease-in-out;
    background-color: white;
    font-size: 12px;
    color: #2b2b2b;
    width: 200px !important;
    border: 1px solid #2b2b2b;
    padding: 16px 32px 16px 32px;    
    line-height: 24px;
    width: 144px;
    text-transform: uppercase;
}
.landing.desktop .flex-container .flex-item-left .brief a:hover,
.landing.desktop .flex-container .flex-item-left .brief button:hover,
.landing.desktop .flex-container .flex-item-left .brief button.pressed {
    border: 1px solid rgba(255, 119, 0, 0.9) !important;
    color: white !important;
    cursor: pointer;
    background-color: rgba(255, 119, 0, 0.9);
}
.landing.desktop .flex-container .flex-item-left .brief .links {
    text-align: left;
    margin: 48px 0;
}
.landing.desktop .flex-container .flex-item-left .brief .links.hidden {
    display: none;
}
.landing.desktop .flex-container .flex-item-left .brief .links a {
    transition: 0.4s ease-in-out;
    font-size: 12px;
    color: #2b2b2b;
    width: 400px;
    border: 1px solid #2b2b2b;
    padding: 16px 32px 16px 32px;    
    line-height: 24px;
    width: 144px;
    text-transform: uppercase;
}
.landing.desktop .flex-container .flex-item-left .brief .links a:nth-child(2) {
    margin: 0 0 0 16px;
}
.landing.desktop .flex-container .flex-item-right {
    background-color: #f2f2f2;
    padding: 0px;
    flex: 80%;
    height: 500px;
}
.landing.desktop .flex-container .flex-item-right .bar {
    background-color: #2b2b2b;
    height: 12px;
    width: 40px;
    position: relative;
    float: right;
    top: 40px;
    right: -420px;
}
.landing.desktop .flex-container .flex-item-right .text {
    height: 40px;
    width: 400px;
    position: relative;
    float: right;
    top: -186px;
    right: -120px;
    transform: rotate(90deg);
}
.landing.desktop .flex-container .flex-item-right .slider-0 {
    height: 700px;
    width: 400px;
    position: relative;
    background-color: #ffffff;
    top: -25px;
    right: 100px;
    float: right;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 0;
}
.landing.desktop .flex-container .flex-item-right .slider-0 img {
    height: 550px !important;
}
.landing.desktop a.contact b {
    font-weight: 700 !important;
    font-family: sans-serif !important;
}
.landing.desktop button.contact b {
    font-weight: 700 !important;
    font-family: sans-serif !important;
}


/**
 *  mobile
 */
.landing.mobile .flex-container {
    display: flex;
    flex-direction: row;
    font-size: 30px;
    text-align: center;
}
.landing.mobile .flex-item-left {
    background-color: #f1f1f1;
    padding: 0px;
    flex: 50%;
    line-height: 0;
}
.landing.mobile .flex-item-left img {
    width: 100%;
    min-height: 400px;
}
  
.landing.mobile .flex-item-right {
    background-color: #f2f2f2;
    padding: 0px;
    flex: 50%;
}
.landing.mobile .flex-item-right .text-1 {
    width: 100%;
    padding: 20px;
    height: 100%;
}
.landing.mobile .flex-item-right .text-1 > div {
    width: 100%;
    font-family: 'Nunito Sans', sans-serif !important;
    color: #2b2b2b;
}
.landing.mobile .flex-item-right .text-1 div p {
    font-size: 18px;
    display: inline-block;
    max-width: 400px;
}
.landing.mobile .flex-item-right .text-1 div h4 {
    font-size: 28px;
}

.landing.mobile .flex-item-right .text-1 div p {
    font-size: 14px;
}

.landing.mobile .flex-item-right .text-1 div .contact {
    color: black;
    transition: 0.6s;
    opacity: 1;
}

.landing.mobile {
    min-height: calc(100vh - 260px);
}
.landing.mobile > div {
    min-height: calc( calc(100vh - 260px) / 2 );
}
.landing.mobile h4 b {
    text-transform: capitalize;
}
.landing.mobile .socials {
    text-align: center;
    margin: 0;
}
.landing.mobile .socials .social {
    display: inline-block;
}
.landing.mobile .socials .social .fa {
    text-align: center;
    padding: 8px 8px 0 8px;
    font-size: 24px;
    height: 54px;
    width: 54px;
    text-decoration: none;
    transition: 0.4s;
}
.landing.mobile .socials .social .fa {
    color: #2b2b2b;
}
.landing.mobile .socials .social .fa:hover {
    color: #8a8a8a;
}

img.load {
    animation-name: load-landing-image;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}

@keyframes load-landing-image {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 800px) {
    .landing.mobile .flex-container {
        flex-direction: column;
    }
    .landing.mobile .flex-item-right .text-1 {
        height: 350px;
    }
    .landing.mobile .flex-item-right .text-1 div h4 {
        font-size: 28px;
    }
    .landing.mobile .flex-item-right .text-1 div p {
        font-size: 14px;
    }
    .landing.mobile .socials .social .fa {
        text-align: center;
        padding: 8px;
        font-size: 24px;
        text-decoration: none;
        transition: 0.4s;
    }
    .landing.mobile #flex-container-bot {
        height: 700px !important;
    }
}

@media (min-width: 661px) and (max-width: 700px) {
    .landing.mobile #flex-container-bot {
        height: 750px !important;
    }
}

@media (min-width: 701px) and (max-width: 800px) {
    .landing.mobile #flex-container-bot {
        height: 800px !important;
    }
}

@media (min-width: 801px) and (max-width: 999px) {
    .landing.mobile #flex-container-bot {
        display: flex;
        flex-direction: row;
        height: 500px !important;
    }
    .landing.mobile #flex-container-bot .flex-item-left {
        order: 2;
    }
    .landing.mobile #flex-container-bot .flex-item-right {
        order: 1;
    }
}

@media (min-width: 901px) and (max-width: 950px) {
    .landing.mobile #flex-container-bot {
        height: 525px !important;
    }
}

@media (min-width: 951px) and (max-width: 999px) {
    .landing.mobile #flex-container-bot {
        height: 550px !important;
    }
}
