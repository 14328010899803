.enquire {
    transition: 1s;
    animation: load-enquire;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;    
}

@keyframes load-enquire {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.enquire a {
    color: rgba(255, 119, 0, 0.8);
    transition: 0.8s;
}
.enquire a:hover {
    color: rgba(255, 119, 0, 0.5);
}

.mobile .enquire {
    text-align: center;
}

.enquire .enquire-form {
    min-height: calc(100vh - 260px);
    width: 100% !important;
}

.enquire .enquire-form h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-top: 34px;
    text-align: center;
}

.enquire .enquire-form div {
    transition: 1s ease-in-out;
    font-size: 16px;
    line-height: 21px;
}

.enquire .enquire-form .action-text {
    hyphens: auto;
}

.enquire .enquire-form .waiver {
    text-align: center;
}

.enquire .enquire-message {
    min-height: calc(100vh - 260px);
    width: 100%;
    text-align: center;
}

.enquire input {
    padding: 8px !important;
    width: calc(100% - 16px) !important;
}
.enquire button {
    height: 64px;
    width: 100%;
    text-align: center;
}
.enquire textarea {
    height: 200px !important;
    padding: 8px !important;
}

.enquire .dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed #ccc;
    padding: 1rem;
    min-height: 250px;
    text-align: center;
    text-transform: capitalize;
}

.enquire .thumbnails {
    display:flex;
    flex-wrap:wrap;
    gap:8px;
    margin-top:1rem;
    justify-content: center;
}
.enquire .thumb {
    width: calc(33% - 5px);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.enquire .thumb img {
    width:100%;
    height:auto;
    border-radius:4px;
}
.enquire .thumb .remove-btn {
    background: transparent;
    border: none;
    font-size: 32px;
    cursor: pointer;
    transition: 0.8s;
}
.enquire .thumb .remove-btn:hover {
    color: rgba(255, 119, 0, 0.8);
}

.enquire .thumb p {
    font-size: 12px !important;
    overflow-wrap: break-word;
    word-break: break-word;
    width: 100%;
}

.enquire .enquire-form p.waiver {
    font-size: 14px !important;
    opacity: 0.6;
}

.enquire .enquire-form p.waiver a {
    opacity: 1;
    font-weight: 800;
}

@media (min-width: 600px) {
    .enquire.container {
        width: 600px;
    }
}

@media screen and (max-width: 801px) {
    @media screen and (max-height: 900px) {
        .enquire .enquire-message {
            min-height: calc(100vh - 500px);
        }
    }
}

@media screen and (max-width: 801px) {
    @media screen and (max-height: 1000px) {
        .enquire .enquire-message {
            min-height: calc(100vh - 600px);
        }
    }
}

@media screen and (max-width: 1001px) {
    @media screen and (min-height: 1001px) {
        .enquire .enquire-message {
            min-height: 400px;
            height: 400px;
        }
    }
} 

@media screen and (min-width: 801px) {
    .enquire .enquire-form h4 {
        font-size: 36px;
    }
    .enquire .enquire-form p {
        font-size: 20px;
        line-height: 24px;
    }
}

@media screen and (max-width: 901px) {
    @media screen and (min-height: 1001px) {
        .enquire .enquire-form {
            min-height: calc(100vh - 460px);
        }
        .enquire .enquire-form h4 {
            font-size: 36px;
        }
    }
}

@media (min-width: 1000px) {
    .enquire.container {
      width: 800px;
    }
}
  