nav {
    height:   150px;
    overflow: hidden;
    background-color: white;
    z-index: 10;
    box-shadow: none !important; 
}
nav .brand-logo img {
    height: 150px;
    width:  150px;
}
nav .booking img {
    height: 150px;
    width:  150px;
}


/**
 *  desktop
 **/
nav.desktop {
    height: 150px;
    position: relative;
}
nav.desktop ul {
    margin:  0 8px 0 140px;
    padding: 40px 16px;
    text-transform: uppercase;
}
nav.desktop ul li {
    height: 100px;
}
nav.desktop ul h6 {
    margin: 18px 32px 0 8px;
    padding: 0px;
    height: 44px;
    font-size: 24px;
    font-weight: 800 !important;
    width: 100%;
    text-align: center;
}
nav.desktop ul a,
nav.desktop ul button {
    font-size: 14px;
    transition: 0.2s ease-in-out;
    border-bottom: 1px solid white;
    margin: 0px 12px;
    padding: 0px;
    height: 44px;
    font-weight: 400 !important;
    font-family: sans-serif !important;
}
nav.desktop ul a:hover,
nav.desktop ul button:hover {
    background-color: rgba(0, 0, 0, 0) !important;
    color: rgba(255, 119, 0, 0.9) !important;
    border-bottom: 1px solid rgba(255, 119, 0, 0.9) !important;
}
nav.desktop ul .right a,
nav.desktop ul .right button {
    cursor: pointer;
    font-weight: 700 !important;
    background-color: white;
    font-size: 12px;
    color: #2b2b2b;
    border: 1px solid #2b2b2b;
    line-height: 16px;
    margin: 1px 0 0 0;
    padding: 16px 42px 36px 42px;
}
nav.desktop ul .right a:hover,
nav.desktop ul .right button:hover {
    border: 1px solid rgba(255, 119, 0, 0.9);
    color: white !important;
    background-color: rgba(255, 119, 0, 0.9) !important;
}


/**
 *  mobile
 **/
nav.mobile {
    height: 272px;
    width:  100%;
    position: relative;
}
nav.mobile .brand {
    margin: 10px 0;
    height: 130px;
}
nav.mobile .title {
    text-transform: uppercase;
    margin: 24px 0 0 0;
}
nav.mobile .title h6 {
    font-size: 24px;
    font-weight: 800 !important;
}
nav.mobile ul {
    text-transform: uppercase;
    height: 64px;
    width: 412px;
    margin: auto;
    padding: 0 10px;
}
nav.mobile ul li {
    display: inline-block;
}
nav.mobile ul li a {
    transition: 0.4s ease-out;
    font-weight: 400 !important;
    height: 52px;
}
nav.mobile ul a:hover {
    background-color: rgba(0, 0, 0, 0) !important;
    color: rgba(255, 119, 0, 0.9) !important;
    border-bottom: 1px solid rgba(255, 119, 0, 0.9) !important;
}
nav.mobile ul .right {
    display: none;
}
