.map {
    height: calc( 100vh - 250px );
    width:  100%;
    overflow: hidden;
    animation: load-map;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;        
}

@keyframes load-map {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.map-marker img {
    border: 2px solid black;
    transition: 0.4s;
    height: 48px;
    width: 48px;
}
.map-marker:hover img {
    border: 2px solid rgba(255, 119, 0);
}

.map-marker img.animate {
    animation-name: load-map-marker;
    animation-duration: 4s;
}

@keyframes load-map-marker {
    0% {
        border: 2px solid rgba(255, 119, 0);
    }
    100% {
        border: 2px solid black;
    }
}

@media screen and (min-width: 801px) {
    .map {
        padding: 32px 16px 32px 16px;
        height: calc( 100vh - 264px );
        width:  100%;
        overflow: hidden;
    }
}
