#marketing-unsubscribe {
    background-color: white;
}

#marketing-unsubscribe h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-top: 34px;
    text-align: center;
}

#marketing-unsubscribe .info-text {
    text-align: center;
    font-size: 16px;
}

