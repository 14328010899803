@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Nunito+Sans:wght@200&display=swap');

* {
    font-family: 'Nunito Sans', sans-serif;
}

body {
    min-width: 420px;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;    /* Firefox     */
}
body::-webkit-scrollbar {
    display: none;
}


input, textarea {
    font-family: 'Nunito Sans', sans-serif !important;
    color: #2b2b2b;
}
input[type=text]:focus, select:focus {
    border-bottom: 1px solid rgba(255, 119, 0, 0.9)  !important;
    box-shadow: 0 1px 0 0 rgba(255, 119, 0, 0.9)  !important;
}
select {
    color: rgba(255, 119, 0, 0.9) !important;
}
.input-field i.active, label.active {
    color: rgba(255, 119, 0, 0.9) !important;
}
.dropdown-content li > a, .dropdown-content li > span {
    color: rgba(255, 119, 0, 0.9) !important;
}

#root {
    max-width: 1220px;
    margin: auto;
    overflow: hidden;
}
