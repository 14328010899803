footer {
    height:     0;
    width:      100%;
    bottom:     0;
    left:       0;
    overflow:   hidden;
    font-size:  12px;
    transition: 1s;
    z-index:    10;
}
footer.desktop {
    height:     100px;
    position:   relative;
    padding:    32px 0;
}
footer.mobile {
    height:     260px;
    position:   relative;
    text-align: center;
    padding:    32px 0;
}

footer .copy {
    font-size: 14px;
}
footer .copy a {
    color: black;
    font-weight: 400;
}
footer.desktop .copy {
    text-align: right;
    font-weight: 400;
    font-size: 12px;
}
footer.mobile .copy {
    font-size: 12px;
}

footer .location {
    font-size: 12px;
    font-weight: 400;
}
footer .location a {
    transition: 0.4s;
}
footer .location a:hover {
    opacity: 0.6;
}


footer.desktop .socials {
    text-align: center;
    margin: 0 0 0 0;
}
footer .socials .social {
    display: inline-block;
}
footer .socials .social .fa {
    text-align: center;
    padding: 8px 0;
    font-size: 30px;
    height: 48px;
    width: 48px;
    text-decoration: none;
    transition: 0.4s;
}
footer.desktop .social .fa {
    padding: 10px 0;
    font-size: 18px;
    height: 40px;
    width: 40px;
}
footer .socials .social .fa:hover {
    opacity: 0.8;
}
footer .socials .social .fa-facebook {
    background: #3B5998;
    color: white;
}
footer .socials .social .fa-instagram {
    background: rgb(0, 123, 181);
    color: white;
}

footer.desktop .socials .social .fa {
    background-color: white;
    text-align: center;
    padding: 8px 8px 0 8px;
    font-size: 24px;
    height: 32px;
    width: 32px;
    text-decoration: none;
    transition: 0.4s;
}
footer.desktop .socials .social .fa {
    color: #2b2b2b;
}
footer.desktop .socials .social .fa:hover {
    color: #8a8a8a;
}
