.booked {
  transition: 1s;
  animation: load-booked;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;    
}

@keyframes load-booked {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.booked a {
  color: rgba(255, 119, 0, 0.8);
  transition: 0.8s;
}
.booked a:hover {
  color: rgba(255, 119, 0, 0.5);
}

.mobile .booked {
  text-align: center;
}

.booked {
  width: 100%;
  text-align: center;
}

.booked h1 {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-top: 34px;
}

.booked .snippet {
  width: 100%;
  text-align: left;
  margin: auto;
  font-size: 16px;
}

.booked table {
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 16px;
}

.booked table th {
  font-weight: 800;
  min-width: 180px;
}
