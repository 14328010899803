#privacy {
    background-color: white;
}

#privacy h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-top: 34px;
    text-align: center;
}

#privacy h2 {
    margin-top: 0;
    font-size: 18px;
}

#privacy section {
    margin-bottom: 20px;
}

#privacy p {
    margin: 10px 0;
}

#privacy main {
    margin-bottom: 32px;
    line-height: 1.6;
}

#privacy p,
#privacy li {
    margin-bottom: 1em;
    list-style-type: disc;
}

#privacy ul {
    margin-bottom: 1em;
    padding-left: 1.5em;
}
