.calendly-badge {
    position: fixed;
    right: 20px;
    bottom: 15px;
    z-index: 9998;
    animation: load-calendly-badge;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;    
}

@keyframes load-calendly-badge {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (min-width: 680px) {
    .calendly-badge {
        display: none;
    }
}

.calendly-badge .calendly-badge-content {
    background: rgba(255, 119, 0, 0.9);
    color: rgb(255, 255, 255);
    display: table-cell;
    width: auto;
    height: 45px;
    padding: 0 30px;
    border-radius: 4px;
    box-shadow: rgba(0,0,0,0.25) 0 2px 5px;
    font-family: sans-serif;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    transition: 0.6s;
}

.calendly-badge .calendly-badge-content:hover {
    border-radius: 8px;
}
