.referrer {
    padding: 24px 0 0 0;
    transition: 1s;
    height: 100px;
    margin: 0 0 20px 0;
}
.referrer.animate {
    animation-name: load-referrer;
    animation-duration: 1.4s;
    animation-fill-mode: forwards;
}

@keyframes load-referrer {
    0% {
        width: 0%;
        font-weight: 0;
        font-size: 0px;
    }
    50% {
        color: #2b2b2b;
        font-weight: 0;
        font-size: 34px;
    }
    100% {
        width: 100%;
        color: rgba(255, 119, 0, 0.9);
        font-weight: 1000;
        font-size: 34px;
    }
}
