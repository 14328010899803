.wall {
    background-color: #181818;
    transition: 1s ease-in-out;
}

.wall .container {
    line-height: 0;
    -webkit-column-count: 3;
    -webkit-column-gap:   0px;
    -moz-column-count:    3;
    -moz-column-gap:      0px;
    column-count:         3;
    column-gap:           0px;
    transition: 1s ease-in-out;
    width: 100%;
    margin: auto;
    overflow: hidden;
}

.wall .container img {
    width:  100% !important;
    height: auto !important;
}

.wall .container img.load {
    animation-name: load-wall-image;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}


@keyframes load-wall-image {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (min-width: 800px) {
    .wall {
        margin: 32px 16px 74px 16px;
    }
}

@media screen and (min-width: 1000px) {
    .wall {
        margin: 32px 24px 74px 24px;
    }
}

@media screen and (max-width: 1399px) {
    .wall .container {
        -moz-column-count:    2;
        -webkit-column-count: 2;
        column-count:         2;
        width: 100%;
    }
    .wall .column {
        flex:      50%;
        max-width: 50%;
    }
}
@media screen and (max-width: 699px) {
    .wall .container {
        -moz-column-count:    1;
        -webkit-column-count: 1;
        column-count:         1;
        width: 100%;
    }
    .wall .column {
        flex:      100%;
        max-width: 100%;
    }
}

.ink {
    background-color: rgba(255, 119, 0, 0.9);
    cursor: pointer;
}
.ink img {
    opacity: 1;
    transition: 0.4s ease-out;
}
