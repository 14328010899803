@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Nunito+Sans:wght@200&display=swap);
.loading {
    height: 4px;
    width:  100%;
    padding: 0;
    margin: 0;
    border: 0;
}
.loading .bar {
    height: 100%;
    width:  0%;
}
.loading.animate .bar {
    animation-name: load;
    animation-duration: 1.4s;
    animation-fill-mode: forwards;
}

@keyframes load {
    0% {
        width: 0%;
        background-color: rgba(255, 119, 0, 0.9);
    }
    70% {
        background-color: rgba(255, 119, 0, 0.9);
    }
    100% {
        width: 100%;
        background-color: white;
    }
}

nav {
    height:   150px;
    overflow: hidden;
    background-color: white;
    z-index: 10;
    box-shadow: none !important; 
}
nav .brand-logo img {
    height: 150px;
    width:  150px;
}
nav .booking img {
    height: 150px;
    width:  150px;
}


/**
 *  desktop
 **/
nav.desktop {
    height: 150px;
    position: relative;
}
nav.desktop ul {
    margin:  0 8px 0 140px;
    padding: 40px 16px;
    text-transform: uppercase;
}
nav.desktop ul li {
    height: 100px;
}
nav.desktop ul h6 {
    margin: 18px 32px 0 8px;
    padding: 0px;
    height: 44px;
    font-size: 24px;
    font-weight: 800 !important;
    width: 100%;
    text-align: center;
}
nav.desktop ul a,
nav.desktop ul button {
    font-size: 14px;
    transition: 0.2s ease-in-out;
    border-bottom: 1px solid white;
    margin: 0px 12px;
    padding: 0px;
    height: 44px;
    font-weight: 400 !important;
    font-family: sans-serif !important;
}
nav.desktop ul a:hover,
nav.desktop ul button:hover {
    background-color: rgba(0, 0, 0, 0) !important;
    color: rgba(255, 119, 0, 0.9) !important;
    border-bottom: 1px solid rgba(255, 119, 0, 0.9) !important;
}
nav.desktop ul .right a,
nav.desktop ul .right button {
    cursor: pointer;
    font-weight: 700 !important;
    background-color: white;
    font-size: 12px;
    color: #2b2b2b;
    border: 1px solid #2b2b2b;
    line-height: 16px;
    margin: 1px 0 0 0;
    padding: 16px 42px 36px 42px;
}
nav.desktop ul .right a:hover,
nav.desktop ul .right button:hover {
    border: 1px solid rgba(255, 119, 0, 0.9);
    color: white !important;
    background-color: rgba(255, 119, 0, 0.9) !important;
}


/**
 *  mobile
 **/
nav.mobile {
    height: 272px;
    width:  100%;
    position: relative;
}
nav.mobile .brand {
    margin: 10px 0;
    height: 130px;
}
nav.mobile .title {
    text-transform: uppercase;
    margin: 24px 0 0 0;
}
nav.mobile .title h6 {
    font-size: 24px;
    font-weight: 800 !important;
}
nav.mobile ul {
    text-transform: uppercase;
    height: 64px;
    width: 412px;
    margin: auto;
    padding: 0 10px;
}
nav.mobile ul li {
    display: inline-block;
}
nav.mobile ul li a {
    transition: 0.4s ease-out;
    font-weight: 400 !important;
    height: 52px;
}
nav.mobile ul a:hover {
    background-color: rgba(0, 0, 0, 0) !important;
    color: rgba(255, 119, 0, 0.9) !important;
    border-bottom: 1px solid rgba(255, 119, 0, 0.9) !important;
}
nav.mobile ul .right {
    display: none;
}

.awssld {
    height: 100%;
}

.awssld__container {
    height: 100%;
}

/**
 *  landing
 */
 .landing {
    animation: load-landing;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;    
  }

  @keyframes load-landing {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  } 


/**
 *  desktop
 */
.landing.desktop {
    height: 100%;
}
.landing.desktop .flex-container {
    display: flex;
    flex-direction: row;
    font-size: 30px;
    text-align: center;    
    margin: 48px 16px 74px 48px;
}
.landing.desktop .flex-container .flex-item-left {
    background-color: #ffffff;
    padding: 0px;
    flex: 20% 1;
    line-height: 0;
    height: 675px;
}
.landing.desktop .flex-container .flex-item-left .title {
    height: 12px;
    width: 400px;
    position: relative;
    top: 8px;
    left: 64px;
    text-align: left;
}
.landing.desktop .flex-container .flex-item-left .title h4 {
    font-size: 36px;
}
.landing.desktop .flex-container .flex-item-left .brief {
    height: 12px;
    width: 480px;
    position: relative;
    top: 124px;
    left: 64px;
    text-align: left;
}
.landing.desktop .flex-container .flex-item-left .brief p {
    font-size: 24px;
    line-height: 24px;
}
.landing.desktop .flex-container .flex-item-left .brief .link {
    text-align: left;
    margin: 48px 0;
}
.landing.desktop .flex-container .flex-item-left .brief .link button {
    transition: 0.2s ease-in-out;
    background-color: white;
    font-size: 12px;
    color: #2b2b2b;
    width: 200px !important;
    border: 1px solid #2b2b2b;
    padding: 16px 32px 16px 32px;    
    line-height: 24px;
    width: 144px;
    text-transform: uppercase;
}
.landing.desktop .flex-container .flex-item-left .brief a:hover,
.landing.desktop .flex-container .flex-item-left .brief button:hover,
.landing.desktop .flex-container .flex-item-left .brief button.pressed {
    border: 1px solid rgba(255, 119, 0, 0.9) !important;
    color: white !important;
    cursor: pointer;
    background-color: rgba(255, 119, 0, 0.9);
}
.landing.desktop .flex-container .flex-item-left .brief .links {
    text-align: left;
    margin: 48px 0;
}
.landing.desktop .flex-container .flex-item-left .brief .links.hidden {
    display: none;
}
.landing.desktop .flex-container .flex-item-left .brief .links a {
    transition: 0.4s ease-in-out;
    font-size: 12px;
    color: #2b2b2b;
    width: 400px;
    border: 1px solid #2b2b2b;
    padding: 16px 32px 16px 32px;    
    line-height: 24px;
    width: 144px;
    text-transform: uppercase;
}
.landing.desktop .flex-container .flex-item-left .brief .links a:nth-child(2) {
    margin: 0 0 0 16px;
}
.landing.desktop .flex-container .flex-item-right {
    background-color: #f2f2f2;
    padding: 0px;
    flex: 80% 1;
    height: 500px;
}
.landing.desktop .flex-container .flex-item-right .bar {
    background-color: #2b2b2b;
    height: 12px;
    width: 40px;
    position: relative;
    float: right;
    top: 40px;
    right: -420px;
}
.landing.desktop .flex-container .flex-item-right .text {
    height: 40px;
    width: 400px;
    position: relative;
    float: right;
    top: -186px;
    right: -120px;
    transform: rotate(90deg);
}
.landing.desktop .flex-container .flex-item-right .slider-0 {
    height: 700px;
    width: 400px;
    position: relative;
    background-color: #ffffff;
    top: -25px;
    right: 100px;
    float: right;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 0;
}
.landing.desktop .flex-container .flex-item-right .slider-0 img {
    height: 550px !important;
}
.landing.desktop a.contact b {
    font-weight: 700 !important;
    font-family: sans-serif !important;
}
.landing.desktop button.contact b {
    font-weight: 700 !important;
    font-family: sans-serif !important;
}


/**
 *  mobile
 */
.landing.mobile .flex-container {
    display: flex;
    flex-direction: row;
    font-size: 30px;
    text-align: center;
}
.landing.mobile .flex-item-left {
    background-color: #f1f1f1;
    padding: 0px;
    flex: 50% 1;
    line-height: 0;
}
.landing.mobile .flex-item-left img {
    width: 100%;
    min-height: 400px;
}
  
.landing.mobile .flex-item-right {
    background-color: #f2f2f2;
    padding: 0px;
    flex: 50% 1;
}
.landing.mobile .flex-item-right .text-1 {
    width: 100%;
    padding: 20px;
    height: 100%;
}
.landing.mobile .flex-item-right .text-1 > div {
    width: 100%;
    font-family: 'Nunito Sans', sans-serif !important;
    color: #2b2b2b;
}
.landing.mobile .flex-item-right .text-1 div p {
    font-size: 18px;
    display: inline-block;
    max-width: 400px;
}
.landing.mobile .flex-item-right .text-1 div h4 {
    font-size: 28px;
}

.landing.mobile .flex-item-right .text-1 div p {
    font-size: 14px;
}

.landing.mobile .flex-item-right .text-1 div .contact {
    color: black;
    transition: 0.6s;
    opacity: 1;
}

.landing.mobile {
    min-height: calc(100vh - 260px);
}
.landing.mobile > div {
    min-height: calc( calc(100vh - 260px) / 2 );
}
.landing.mobile h4 b {
    text-transform: capitalize;
}
.landing.mobile .socials {
    text-align: center;
    margin: 0;
}
.landing.mobile .socials .social {
    display: inline-block;
}
.landing.mobile .socials .social .fa {
    text-align: center;
    padding: 8px 8px 0 8px;
    font-size: 24px;
    height: 54px;
    width: 54px;
    text-decoration: none;
    transition: 0.4s;
}
.landing.mobile .socials .social .fa {
    color: #2b2b2b;
}
.landing.mobile .socials .social .fa:hover {
    color: #8a8a8a;
}

img.load {
    animation-name: load-landing-image;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}

@keyframes load-landing-image {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 800px) {
    .landing.mobile .flex-container {
        flex-direction: column;
    }
    .landing.mobile .flex-item-right .text-1 {
        height: 350px;
    }
    .landing.mobile .flex-item-right .text-1 div h4 {
        font-size: 28px;
    }
    .landing.mobile .flex-item-right .text-1 div p {
        font-size: 14px;
    }
    .landing.mobile .socials .social .fa {
        text-align: center;
        padding: 8px;
        font-size: 24px;
        text-decoration: none;
        transition: 0.4s;
    }
    .landing.mobile #flex-container-bot {
        height: 700px !important;
    }
}

@media (min-width: 661px) and (max-width: 700px) {
    .landing.mobile #flex-container-bot {
        height: 750px !important;
    }
}

@media (min-width: 701px) and (max-width: 800px) {
    .landing.mobile #flex-container-bot {
        height: 800px !important;
    }
}

@media (min-width: 801px) and (max-width: 999px) {
    .landing.mobile #flex-container-bot {
        display: flex;
        flex-direction: row;
        height: 500px !important;
    }
    .landing.mobile #flex-container-bot .flex-item-left {
        order: 2;
    }
    .landing.mobile #flex-container-bot .flex-item-right {
        order: 1;
    }
}

@media (min-width: 901px) and (max-width: 950px) {
    .landing.mobile #flex-container-bot {
        height: 525px !important;
    }
}

@media (min-width: 951px) and (max-width: 999px) {
    .landing.mobile #flex-container-bot {
        height: 550px !important;
    }
}

footer {
    height:     0;
    width:      100%;
    bottom:     0;
    left:       0;
    overflow:   hidden;
    font-size:  12px;
    transition: 1s;
    z-index:    10;
}
footer.desktop {
    height:     100px;
    position:   relative;
    padding:    32px 0;
}
footer.mobile {
    height:     260px;
    position:   relative;
    text-align: center;
    padding:    32px 0;
}

footer .copy {
    font-size: 14px;
}
footer .copy a {
    color: black;
    font-weight: 400;
}
footer.desktop .copy {
    text-align: right;
    font-weight: 400;
    font-size: 12px;
}
footer.mobile .copy {
    font-size: 12px;
}

footer .location {
    font-size: 12px;
    font-weight: 400;
}
footer .location a {
    transition: 0.4s;
}
footer .location a:hover {
    opacity: 0.6;
}


footer.desktop .socials {
    text-align: center;
    margin: 0 0 0 0;
}
footer .socials .social {
    display: inline-block;
}
footer .socials .social .fa {
    text-align: center;
    padding: 8px 0;
    font-size: 30px;
    height: 48px;
    width: 48px;
    text-decoration: none;
    transition: 0.4s;
}
footer.desktop .social .fa {
    padding: 10px 0;
    font-size: 18px;
    height: 40px;
    width: 40px;
}
footer .socials .social .fa:hover {
    opacity: 0.8;
}
footer .socials .social .fa-facebook {
    background: #3B5998;
    color: white;
}
footer .socials .social .fa-instagram {
    background: rgb(0, 123, 181);
    color: white;
}

footer.desktop .socials .social .fa {
    background-color: white;
    text-align: center;
    padding: 8px 8px 0 8px;
    font-size: 24px;
    height: 32px;
    width: 32px;
    text-decoration: none;
    transition: 0.4s;
}
footer.desktop .socials .social .fa {
    color: #2b2b2b;
}
footer.desktop .socials .social .fa:hover {
    color: #8a8a8a;
}


.calendly-inline-widget {
    animation: load-calendly;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}

@keyframes load-calendly {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.calendly {
    transition: 1s;
    animation: load-calendly;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}

@keyframes load-calendly {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.calendly a {
    color: rgba(255, 119, 0, 0.8);
    transition: 0.8s;
}

.calendly a:hover {
    color: rgba(255, 119, 0, 0.5);
}

.mobile .calendly {
    text-align: center;
}

.calendly {
    width: 100%;
    text-align: center;
}

.calendly h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-top: 34px;
}

.calendly .disclaimer,
.calendly .snippet {
    width: 100%;
    text-align: left;
    margin: auto;
    font-size: 16px;
    transition: 0s;
}

.calendly .placeholder {
    height: 400px;
    width: 70%;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 8px 0px;
    margin: 64px auto;
}

.calendly .placeholder {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    overflow: hidden;
}

.calendly .placeholder .icon svg {
    width: 64px;
    height: 64px;
    animation: calendly-loading 2s cubic-bezier(0.25, 0.1, 0.25, 1) infinite;
}

.calendly .waiver {
    font-size: 14px;
    opacity: 0.75;
}

.calendly .waiver a {
    opacity: 1;
    font-weight: 800;
}

@keyframes calendly-loading {
    0% {
        transform: scale(0.9);
        opacity: 0.8;
    }

    50% {
        transform: scale(1.1);
        opacity: 1;
    }

    100% {
        transform: scale(0.9);
        opacity: 0.8;
    }
}

@media (max-width: 650px) {
    .calendly .placeholder {
        width: 100%;
        margin: 48px auto;
        box-shadow: none;
    }
}

@media (max-width: 800px) {}

@media (max-width: 750px) {
    .calendly .snippet {
        margin-top: 32px;
        margin-bottom: 16px;
    }

    .calendly .disclaimer {
        margin-bottom: 32px;
    }
}

@media (min-width: 600px) {

    .calendly .disclaimer,
    .calendly .snippet {
        width: 100%;
        max-width: 800px;
        text-align: left;
    }

    .calendly .disclaimer {
        margin-top: 32px;
        margin-bottom: 16px;
    }

    .calendly .waiver {
        margin: auto;
        margin-bottom: 32px;
        width: 550px;
    }

}

@media (min-width: 1000px) {

    .calendly .disclaimer,
    .calendly .snippet {
        text-align: left;
    }

    .calendly .disclaimer,
    .calendly .snippet,
    .calendly .waiver {
        width: 800px;
    }

    .calendly .placeholder {
        width: 1058px;
    }

    .calendly .container {
        width: 85%;
    }
}

[data-container="booking-container"] {
    box-shadow: none !important;
}
#contact {
    background-color: white;
}

.booked {
  transition: 1s;
  animation: load-booked;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;    
}

@keyframes load-booked {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.booked a {
  color: rgba(255, 119, 0, 0.8);
  transition: 0.8s;
}
.booked a:hover {
  color: rgba(255, 119, 0, 0.5);
}

.mobile .booked {
  text-align: center;
}

.booked {
  width: 100%;
  text-align: center;
}

.booked h1 {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-top: 34px;
}

.booked .snippet {
  width: 100%;
  text-align: left;
  margin: auto;
  font-size: 16px;
}

.booked table {
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 16px;
}

.booked table th {
  font-weight: 800;
  min-width: 180px;
}

#contact {
    background-color: white;
}

.referrer {
    padding: 24px 0 0 0;
    transition: 1s;
    height: 100px;
    margin: 0 0 20px 0;
}
.referrer.animate {
    animation-name: load-referrer;
    animation-duration: 1.4s;
    animation-fill-mode: forwards;
}

@keyframes load-referrer {
    0% {
        width: 0%;
        font-weight: 0;
        font-size: 0px;
    }
    50% {
        color: #2b2b2b;
        font-weight: 0;
        font-size: 34px;
    }
    100% {
        width: 100%;
        color: rgba(255, 119, 0, 0.9);
        font-weight: 1000;
        font-size: 34px;
    }
}

.enquire {
    transition: 1s;
    animation: load-enquire;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;    
}

@keyframes load-enquire {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.enquire a {
    color: rgba(255, 119, 0, 0.8);
    transition: 0.8s;
}
.enquire a:hover {
    color: rgba(255, 119, 0, 0.5);
}

.mobile .enquire {
    text-align: center;
}

.enquire .enquire-form {
    min-height: calc(100vh - 260px);
    width: 100% !important;
}

.enquire .enquire-form h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-top: 34px;
    text-align: center;
}

.enquire .enquire-form div {
    transition: 1s ease-in-out;
    font-size: 16px;
    line-height: 21px;
}

.enquire .enquire-form .action-text {
    -webkit-hyphens: auto;
            hyphens: auto;
}

.enquire .enquire-form .waiver {
    text-align: center;
}

.enquire .enquire-message {
    min-height: calc(100vh - 260px);
    width: 100%;
    text-align: center;
}

.enquire input {
    padding: 8px !important;
    width: calc(100% - 16px) !important;
}
.enquire button {
    height: 64px;
    width: 100%;
    text-align: center;
}
.enquire textarea {
    height: 200px !important;
    padding: 8px !important;
}

.enquire .dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed #ccc;
    padding: 1rem;
    min-height: 250px;
    text-align: center;
    text-transform: capitalize;
}

.enquire .thumbnails {
    display:flex;
    flex-wrap:wrap;
    grid-gap:8px;
    gap:8px;
    margin-top:1rem;
    justify-content: center;
}
.enquire .thumb {
    width: calc(33% - 5px);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.enquire .thumb img {
    width:100%;
    height:auto;
    border-radius:4px;
}
.enquire .thumb .remove-btn {
    background: transparent;
    border: none;
    font-size: 32px;
    cursor: pointer;
    transition: 0.8s;
}
.enquire .thumb .remove-btn:hover {
    color: rgba(255, 119, 0, 0.8);
}

.enquire .thumb p {
    font-size: 12px !important;
    overflow-wrap: break-word;
    word-break: break-word;
    width: 100%;
}

.enquire .enquire-form p.waiver {
    font-size: 14px !important;
    opacity: 0.6;
}

.enquire .enquire-form p.waiver a {
    opacity: 1;
    font-weight: 800;
}

@media (min-width: 600px) {
    .enquire.container {
        width: 600px;
    }
}

@media screen and (max-width: 801px) {
    @media screen and (max-height: 900px) {
        .enquire .enquire-message {
            min-height: calc(100vh - 500px);
        }
    }
}

@media screen and (max-width: 801px) {
    @media screen and (max-height: 1000px) {
        .enquire .enquire-message {
            min-height: calc(100vh - 600px);
        }
    }
}

@media screen and (max-width: 1001px) {
    @media screen and (min-height: 1001px) {
        .enquire .enquire-message {
            min-height: 400px;
            height: 400px;
        }
    }
} 

@media screen and (min-width: 801px) {
    .enquire .enquire-form h4 {
        font-size: 36px;
    }
    .enquire .enquire-form p {
        font-size: 20px;
        line-height: 24px;
    }
}

@media screen and (max-width: 901px) {
    @media screen and (min-height: 1001px) {
        .enquire .enquire-form {
            min-height: calc(100vh - 460px);
        }
        .enquire .enquire-form h4 {
            font-size: 36px;
        }
    }
}

@media (min-width: 1000px) {
    .enquire.container {
      width: 800px;
    }
}
  
#contact {
    background-color: white;
}

.calendly-badge {
    position: fixed;
    right: 20px;
    bottom: 15px;
    z-index: 9998;
    animation: load-calendly-badge;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;    
}

@keyframes load-calendly-badge {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (min-width: 680px) {
    .calendly-badge {
        display: none;
    }
}

.calendly-badge .calendly-badge-content {
    background: rgba(255, 119, 0, 0.9);
    color: rgb(255, 255, 255);
    display: table-cell;
    width: auto;
    height: 45px;
    padding: 0 30px;
    border-radius: 4px;
    box-shadow: rgba(0,0,0,0.25) 0 2px 5px;
    font-family: sans-serif;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    transition: 0.6s;
}

.calendly-badge .calendly-badge-content:hover {
    border-radius: 8px;
}

.wall {
    background-color: #181818;
    transition: 1s ease-in-out;
}

.wall .container {
    line-height: 0;
    -webkit-column-count: 3;
    -webkit-column-gap:   0px;
    -moz-column-count:    3;
    -moz-column-gap:      0px;
    column-count:         3;
    grid-column-gap:           0px;
    column-gap:           0px;
    transition: 1s ease-in-out;
    width: 100%;
    margin: auto;
    overflow: hidden;
}

.wall .container img {
    width:  100% !important;
    height: auto !important;
}

.wall .container img.load {
    animation-name: load-wall-image;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}


@keyframes load-wall-image {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (min-width: 800px) {
    .wall {
        margin: 32px 16px 74px 16px;
    }
}

@media screen and (min-width: 1000px) {
    .wall {
        margin: 32px 24px 74px 24px;
    }
}

@media screen and (max-width: 1399px) {
    .wall .container {
        column-count:         2;
        width: 100%;
    }
    .wall .column {
        flex:      50% 1;
        max-width: 50%;
    }
}
@media screen and (max-width: 699px) {
    .wall .container {
        column-count:         1;
        width: 100%;
    }
    .wall .column {
        flex:      100% 1;
        max-width: 100%;
    }
}

.ink {
    background-color: rgba(255, 119, 0, 0.9);
    cursor: pointer;
}
.ink img {
    opacity: 1;
    transition: 0.4s ease-out;
}

.map {
    height: calc( 100vh - 250px );
    width:  100%;
    overflow: hidden;
    animation: load-map;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;        
}

@keyframes load-map {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.map-marker img {
    border: 2px solid black;
    transition: 0.4s;
    height: 48px;
    width: 48px;
}
.map-marker:hover img {
    border: 2px solid rgba(255, 119, 0);
}

.map-marker img.animate {
    animation-name: load-map-marker;
    animation-duration: 4s;
}

@keyframes load-map-marker {
    0% {
        border: 2px solid rgba(255, 119, 0);
    }
    100% {
        border: 2px solid black;
    }
}

@media screen and (min-width: 801px) {
    .map {
        padding: 32px 16px 32px 16px;
        height: calc( 100vh - 264px );
        width:  100%;
        overflow: hidden;
    }
}

#privacy {
    background-color: white;
}

#privacy h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-top: 34px;
    text-align: center;
}

#privacy h2 {
    margin-top: 0;
    font-size: 18px;
}

#privacy section {
    margin-bottom: 20px;
}

#privacy p {
    margin: 10px 0;
}

#privacy main {
    margin-bottom: 32px;
    line-height: 1.6;
}

#privacy p,
#privacy li {
    margin-bottom: 1em;
    list-style-type: disc;
}

#privacy ul {
    margin-bottom: 1em;
    padding-left: 1.5em;
}

#terms {
    background-color: white;
}

#terms h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-top: 34px;
    text-align: center;
}

#terms h2 {
    margin-top: 0;
    font-size: 18px;
}

#terms section {
    margin-bottom: 20px;
}

#terms p {
    margin: 10px 0;
}

#terms main {
    margin-bottom: 32px;
}

#terms main {
    margin-bottom: 32px;
    line-height: 1.6;
}

#terms p,
#terms li {
    margin-bottom: 1em;
    list-style-type: disc;
}

#terms ul {
    margin-bottom: 1em;
    padding-left: 1.5em;
}

#marketing-unsubscribe {
    background-color: white;
}

#marketing-unsubscribe h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-top: 34px;
    text-align: center;
}

#marketing-unsubscribe .info-text {
    text-align: center;
    font-size: 16px;
}


* {
    font-family: 'Nunito Sans', sans-serif;
}

body {
    min-width: 420px;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;    /* Firefox     */
}
body::-webkit-scrollbar {
    display: none;
}


input, textarea {
    font-family: 'Nunito Sans', sans-serif !important;
    color: #2b2b2b;
}
input[type=text]:focus, select:focus {
    border-bottom: 1px solid rgba(255, 119, 0, 0.9)  !important;
    box-shadow: 0 1px 0 0 rgba(255, 119, 0, 0.9)  !important;
}
select {
    color: rgba(255, 119, 0, 0.9) !important;
}
.input-field i.active, label.active {
    color: rgba(255, 119, 0, 0.9) !important;
}
.dropdown-content li > a, .dropdown-content li > span {
    color: rgba(255, 119, 0, 0.9) !important;
}

#root {
    max-width: 1220px;
    margin: auto;
    overflow: hidden;
}

