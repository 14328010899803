.loading {
    height: 4px;
    width:  100%;
    padding: 0;
    margin: 0;
    border: 0;
}
.loading .bar {
    height: 100%;
    width:  0%;
}
.loading.animate .bar {
    animation-name: load;
    animation-duration: 1.4s;
    animation-fill-mode: forwards;
}

@keyframes load {
    0% {
        width: 0%;
        background-color: rgba(255, 119, 0, 0.9);
    }
    70% {
        background-color: rgba(255, 119, 0, 0.9);
    }
    100% {
        width: 100%;
        background-color: white;
    }
}
